import { theme as chakraTheme, extendTheme } from '@chakra-ui/react';

/**
 * More info about configuring default themes here:
 * https://github.com/chakra-ui/chakra-ui/tree/master/packages/chakra-ui/src/theme
 * https://system-ui.com/theme/
 */

/**
 * Colors
 * Sourced from TailwindUI:
 * https://cdn.jsdelivr.net/npm/@tailwindcss/ui@latest/dist/tailwind-ui.css
 *
 * How TailwindUI colors have changed over Tailwind:
 * https://www.notion.so/How-Tailwind-UI-Extends-Tailwind-2f74104b886c47b3b7a24a65f1d24935
 */

const colors = {
  gray: {
    50: '#f9fafb',
    100: '#f4f5f7',
    200: '#e5e7eb',
    300: '#d2d6dc',
    400: '#9fa6b2',
    500: '#6b7280',
    600: '#4b5563',
    700: '#374151',
    800: '#252f3f',
    900: '#161e2e',
  },
  green: {
    '50': '#EFF7EE',
    '100': '#D3E8CE',
    '200': '#B7DAAF',
    '300': '#9BCB90',
    '400': '#7FBC71',
    '500': '#63AE51',
    '600': '#508B41',
    '700': '#3C6831',
    '800': '#284521',
    '900': '#142310',
  },
  orange: {
    '50': '#FEF4E7',
    '100': '#FBE0BC',
    '200': '#F8CB91',
    '300': '#F5B765',
    '400': '#F3A33A',
    '500': '#F08F0F',
    '600': '#C0720C',
    '700': '#905609',
    '800': '#603906',
    '900': '#301C03',
  },
  blue: {
    50: '#EDF3FA',
    100: '#DBE7F5',
    200: '#C9DBF0',
    300: '#A5C3E6',
    400: '#7BA4D6',
    500: '#5B80B3',
    600: '#466EA7',
    700: '#3E65A3',
    800: '#2A4A81',
    900: '#283E66',
  },
  red: {
    '50': '#FAEAEB',
    '100': '#F1C5C6',
    '200': '#E9A0A1',
    '300': '#E07B7D',
    '400': '#D75658',
    '500': '#CF3034',
    '600': '#A52729',
    '700': '#7C1D1F',
    '800': '#531315',
    '900': '#290A0A',
  },
  indigo: {
    50: '#f0f5ff',
    100: '#e5edff',
    200: '#cddbfe',
    300: '#b4c6fc',
    400: '#8da2fb',
    500: '#6875f5',
    600: '#5850ec',
    700: '#5145cd',
    800: '#42389d',
    900: '#362f78',
  },
  yellow: {
    50: '#fffbeb',
    100: '#fef3c7',
    200: '#fde68a',
    300: '#fcd34d',
    400: '#fbbf24',
    500: '#f59e0b',
    600: '#d97706',
    700: '#b45309',
    800: '#92400e',
    900: '#78350f',
  },
};

const fonts = {
  heading: `'B612', 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif`,
  body: `'B612', 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif`,
  mono: `'B612 Mono', 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;`,
  plex: `'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif`,
  'plex-mono': `'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;`,
};

const lineHeights = {
  /**
   * Reference:
   * https://tailwindcss.com/docs/line-height/#app
   */
  'leading-4': '1',
  'leading-5': '1.25',
  'leading-6': '1.5',
};

const letterSpacings = {
  /**
   * Reference:
   * https://tailwindcss.com/docs/letter-spacing/#app
   */
  'tracking-wider': chakraTheme.letterSpacings.wider,
};

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        bg: 'gray.100',
      },
    },
  },
  colors,
  fonts,
  letterSpacings,
  lineHeights,
});

export default theme;
