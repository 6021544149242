import React, { ComponentProps, useMemo, useEffect } from 'react';
import Navbar from '@bit/matternet.shared.components.navbar';
import { useSession, signOut } from 'next-auth/client';
import packageJson from 'package.json';
import { titleCase } from 'title-case';
import LogRocket from 'logrocket';
import { CALLBACK_URL } from '@/src/shared/config/constants';
import Sentry from '@/src/lib/initSentry';
import openRequestedWindow from '@/src/utils/openRequestedWindow';

type NavbarMenuConfig = ComponentProps<typeof Navbar>['menuConfig'];

const AuthenticatedNavbar = () => {
  const [session] = useSession();
  const email = session?.user?.email;
  const id = session?.user?.id;

  useEffect(() => {
    if (email && id) {
      LogRocket.identify(id, {
        email,
      });
      Sentry.setUser({ email, id: email });
    }
  }, [email, id]);

  const menuConfig: NavbarMenuConfig = useMemo(() => {
    if (session) {
      return {
        name: session.user.email,
        avatarSrc: session.user.image,
        menuGroups: [
          {
            title: 'Profile',
            menuItems: [
              {
                label: session.user.email,
                onClick: () => {},
              },
            ],
          },
          {
            title: 'Screens',
            menuItems: [
              {
                label: 'Aircraft',
                onClick: () =>
                  openRequestedWindow(
                    `${window.location.origin}/vehicle`,
                    'vehicle',
                  ),
              },
              {
                label: 'Map',
                onClick: () =>
                  openRequestedWindow(`${window.location.origin}/map`, 'map'),
              },
            ],
          },
          {
            title: `Version ${packageJson.version} - ${titleCase(
              process.env.REVISION_ENVIRONMENT || process.env.NODE_ENV,
            )}`,
            menuItems: [
              {
                label: 'Logout',
                onClick: () => {
                  signOut({
                    callbackUrl: CALLBACK_URL,
                  });
                },
              },
            ],
          },
        ],
      };
    }

    return undefined;
  }, [session]);

  return <Navbar title="Mission Control" menuConfig={menuConfig} />;
};

export default AuthenticatedNavbar;
