type WindowObjectReference = {
  [strWindowName: string]: Window | null;
};

const windowObjectReference: WindowObjectReference = {};

function openRequestedWindow(strUrl: string, strWindowName: string) {
  if (
    !windowObjectReference[strWindowName] ||
    windowObjectReference[strWindowName]?.closed
  ) {
    windowObjectReference[strWindowName] = window.open(
      strUrl,
      strWindowName,
      'resizable,scrollbars,status',
    );
  } else {
    windowObjectReference[strWindowName]?.focus();
  }
}

export default openRequestedWindow;
