/* Sentry setup example https://github.com/vercel/next.js/tree/canary/examples/with-sentry */
import * as Sentry from '@sentry/nextjs';
import { RewriteFrames } from '@sentry/integrations';

if (process.env.SENTRY_DSN) {
  const integrations = [];
  if (
    process.env.NEXT_IS_SERVER === 'true' &&
    process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
  ) {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: (frame: any) => {
          /* eslint-disable no-param-reassign */
          frame.filename = frame.filename.replace(
            process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
            'app:///',
          );

          frame.filename = frame.filename.replace('.next', '_next');
          /* eslint-enable no-param-reassign */
          return frame;
        },
      }),
    );
  }

  Sentry.init({
    // integrations,
    dsn: process.env.SENTRY_DSN,
    release: process.env.REVISION_TAG,
    environment: process.env.REVISION_ENVIRONMENT,
  });
}

export default Sentry;
