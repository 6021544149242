import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import Sentry from '@/src/lib/initSentry';

export const initLogRocket = () => {
  if (typeof window !== 'undefined') {
    LogRocket.init('matternet/mission-control-xwufp', {
      // ex: "mttr-dev"
      release: `${process.env.REVISION_ENVIRONMENT}-${process.env.REVISION_SHA1}-${process.env.REVISION_BRANCH}`,
    });
    setupLogRocketReact(LogRocket);

    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  }
};

export default LogRocket;
